// App Configuration

const settings = {
  apiURL: process.env.REACT_APP_APIURL,
  siteAvailability: "public",
  samplesEndpoint: "/samples",
  trackHubPrefix: "http://genome.ucsc.edu/cgi-bin/hgTracks?db=sacCer3&hubUrl=",
};

module.exports = {
  settings: settings,
};
