import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import CardActions from "@material-ui/core/CardActions";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DownloadIcon from "@material-ui/icons/GetApp";

// contextAPI
import DataContext from "../DataContext";

// component styles.
const styles = (theme) => ({
  fullList: {
    width: "auto",
    height: "100%",
  },
  table: {
    width: "auto",
    margin: "0 auto",
  },
  borderLine: {
    border: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
  linkTags: {
    color: theme.palette.primary,
    textDecoration: "none",
  },
});

class SampleStatsTable extends React.Component {
  // using the context
  static contextType = DataContext;

  render() {
    const { classes } = this.props;
    const centerContent = {
      textAlign: "center",
    };

    // creating table rows
    const sampleStats = this.props.stats.map((item) => {
      console.log(item);

      return (
        <TableRow key={item._id} className={classes.borderLine}>
          <TableCell align="left" className={classes.borderLine}>
            {item.sampleID}
          </TableCell>
          <TableCell align="left" className={classes.borderLine}>
            {item.summaryInfo.data["Assay type"]}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.lab}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            {item.affiliation}
          </TableCell>
          <TableCell align="center" className={classes.borderLine}>
            <a
              href={item.downloadLink}
              rel="noopener noreferrer"
              className={classes.linkTags}
            >
              <DownloadIcon fontSize="small" color="primary" />
            </a>
          </TableCell>
        </TableRow>
      );
    });

    return (
      <CardContent className={classes.fullList}>
        <CardActions>
          <Grid container alignItems={"center"} justify={"space-between"}>
            <Grid item sm={"auto"}>
              <Tooltip title="Go Back" aria-label="Go Back">
                <Button
                  size="small"
                  color="primary"
                  onClick={this.props.handleBack}
                >
                  <ArrowBack />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sm={"auto"}>
              {/* Add Actions here */}
            </Grid>
          </Grid>
        </CardActions>

        <Divider />
        <br />

        <CardContent>
          <Typography style={centerContent} variant="h6">
            Showing metadata for {this.props.stats.length}
            Sample(s)
          </Typography>
        </CardContent>

        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.borderLine}>
              <TableCell align="center" className={classes.borderLine}>
                SampleID
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Assay
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Lab
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Affiliation
              </TableCell>
              <TableCell align="center" className={classes.borderLine}>
                Download
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{sampleStats}</TableBody>
        </Table>
      </CardContent>
    );
  }
}

SampleStatsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SampleStatsTable);
