import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Button, CardActions } from "@material-ui/core";
import { Link } from "react-router-dom";
// import InfoIcon from "@material-ui/icons/InfoOutlined";
import ListIcon from "@material-ui/icons/ViewList";

// Sub Components
import Search from "./Search";
import DataContext from "./DataContext";
import PhasePie from "./SubComponents/PhasePie";
import CellLinePie from "./SubComponents/CellLinePie";
import DataTable from "./SubComponents/DataTable";

// import image assets
import pennstatelogo from "../pennstatelogo.png";
import cegrlogo from "../CEGRlogo.png";
import pdfLogo from "../pdfLogo.png";

const styles = (theme) => ({
  root: {
    height: "100%",
    maxWidth: 980,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    padding: 20,
  },
  footer: {
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    flexShrink: 0,
  },
  jumbotron: {
    padding: "2rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "4rem 0rem",
    },
  },
  container: {
    maxWidth: "1140px",
    paddingRight: 15,
    paddingLeft: 15,
    margin: "auto",
  },
  center: {
    margin: "auto",
    maxWidth: 1140,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  companyLogo: {
    width: 160,
  },
  copyrightStyle: {
    textAlign: "center",
  },
  links: {
    textDecoration: "none",
  },
  pieChartHolder: {
    // border: "2px solid green",
  },
  pdfIcon: {
    height: 100,
    width: 100,
  },
});

class SimpleLandingPage extends React.Component {
  // using the context
  static contextType = DataContext;

  render() {
    const { classes } = this.props;
    const currentYear = new Date().getFullYear();

    // Setting the title of the browser tab
    document.title = "Protein Capture Reagents Program Validation";

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Paper square>
            <CardActions>
              <Grid container alignItems={"center"} justify={"space-between"}>
                <Grid item sm={"auto"}>
                  <Link to="/explore" className={classes.links}>
                    <Button size="small" color="primary">
                      <ListIcon className={classes.leftIcon} />
                      Explore
                    </Button>
                  </Link>

                  {/* <Link to="/help" className={classes.links}>
                    <Button size="small" color="primary">
                      <InfoIcon className={classes.leftIcon} />
                      Help
                    </Button>
                  </Link> */}
                </Grid>
                <Grid item sm={"auto"} />
              </Grid>
            </CardActions>
          </Paper>
          <Paper square>
            {/* Jumbotron or main message */}
            <div className={classes.jumbotron}>
              <div className={classes.container}>
                <Typography variant="h4" gutterBottom>
                  Protein Capture Reagents Program Validation
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                  A community resource of renewable, high-quality protein
                  capture reagents to understand the critical role the multitude
                  of cellular proteins play in development, health, and disease.
                </Typography>
                <Divider />
                <Typography
                  variant="caption"
                  component="p"
                  gutterBottom
                ></Typography>
              </div>
            </div>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item></Grid>

              {/* SearchBar */}
              <Grid item>
                <Search suggestions={this.context.searchOptions} />
              </Grid>

              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    {/* URL for the pdf document */}
                    <a
                      href="https://localhost:8090/images/ChIP-exo_Validation_Document.pdf"
                      rel="noopener noreferrer"
                      className={classes.links}
                    >
                      <img
                        src={pdfLogo}
                        alt="pdflogo"
                        className={classes.pdfIcon}
                      />
                    </a>
                  </Grid>
                  <Grid item style={{ paddingTop: 25 }}>
                    <span>
                      ChIP-exo <br /> Validation <br /> Document
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <br />

          {/* Pie Charts */}
          <Paper square>
            <Grid item>
              <div className={classes.container}>
                <Typography variant="h6">Overview</Typography>
                <Divider />
                <br />
                <Typography variant="body1">
                  The goal of the Common Fund’s Protein Capture Reagents program
                  is to develop a community resource of renewable, high-quality
                  protein capture reagents, with a focus on the creation of
                  transcription factor reagents and testing next generation
                  capture technologies. The program has developed new resources
                  and tools to understand the critical role the multitude of
                  cellular proteins play in development, health, and disease.
                  These resources will support a wide-range of research and
                  clinical applications by enabling the isolation and tracking
                  of proteins of interest.
                </Typography>
                <br />
                <Divider />
              </div>
            </Grid>
            <br />
            <Grid
              container
              direction="row"
              spacing={2}
              style={{ margin: "0 auto" }}
            >
              <Grid item>
                <CellLinePie />
              </Grid>
              <Grid item>
                <PhasePie />
              </Grid>
            </Grid>
          </Paper>
          <br />

          {/* Data table */}
          <DataTable data={this.context.data} />
          <br />

          <Paper square>
            <Grid container spacing={2} direction="column" alignItems="center">
              {/* Footer  Section */}
              <Grid item className={classes.center}>
                <Grid
                  container
                  spacing={4}
                  alignItems={"center"}
                  direction="row"
                  justify="center"
                  alignContent="center"
                  className={classes.footer}
                >
                  <Grid item>
                    <img
                      src={pennstatelogo}
                      alt="pennstateLogo"
                      style={{ width: 160 }}
                    />
                  </Grid>
                  <Grid item>
                    <img src={cegrlogo} alt="cegrlogo" style={{ height: 55 }} />
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </Grid>

              {/* copyright info */}
              <Grid item>
                <Typography variant="body2" className={classes.copyrightStyle}>
                  &copy; {currentYear} Pennsylvania State University
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }
}

SimpleLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleLandingPage);
