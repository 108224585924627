import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { CardContent } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import MUIDataTable from "mui-datatables";

// Component styles
const styles = {};

function DataTable(props) {
  //   const { classes } = props;

  // SampleID,Target,Cell line,Antibody,Project Phase,Assay,QC Passed
  const tableData = props.data.map((item) => {
    if (item.tableVisible === true) {
      return [
        item.sampleID,
        item.target,
        item.summaryInfo.data["Cell line"],
        item.summaryInfo.data.Antibody,
        item.summaryInfo.data.ProjectID,
        item.summaryInfo.data["Assay type"],
        item.qcValue,
      ];
    } else {
      console.log(item.sampleID, item.target);
    }
  });

  const columns = [
    {
      name: "Sample ID",
      label: "Sample ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Target",
      label: "Target",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Cell Line",
      label: "Cell Line",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Antibody",
      label: "Antibody",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Project Phase",
      label: "Project Phase",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Assay",
      label: "Assay",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "QC Passed",
      label: "QC Passed",
      options: {
        filter: true,
        sort: true,
        sortDirection: "desc",
      },
    },
  ];

  // Function to open sample page on target click
  function rowClick(rowData, rowMeta) {
    // console.log(rowData, rowMeta);
    if (!rowData[1]) {
      console.log(rowData);
    } else {
      // https://stackoverflow.com/a/52238733
      // Relaying the sampleID to the samplePage so
      // that a particular tab will be highlighted upon arrival
      props.history.push({
        pathname: "/factor/" + rowData[1],
        data: rowData[0],
      });
    }
  }

  const options = {
    filterType: "dropdown",
    elevation: 1,
    selectableRows: "none",
    responsive: "scrollMaxHeight",
    print: false,
    downloadOptions: {
      filename: "ProteinCaptureReagentsProgramValidation-samples.csv",
      separator: ",",
    },
    rowsPerPageOptions: [10, 15, 50, 100],
    onRowClick: rowClick,
  };

  return (
    <MUIDataTable
      title={""}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DataTable));
